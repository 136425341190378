<template>
  <v-container class="payment" :class="{ 'w-90': $vuetify.breakpoint.lgAndUp }">
    <PaymentStepper :step="3" class="mx-0 mx-sm-auto mt-3 mt-sm-6" />

    <CategoryTitle
      :category="category"
      :showOnMobile="true"
      :centered="$vuetify.breakpoint.xs"
      :showDescription="false"
    />

    <v-row no-gutters>
      <v-col
        class="categoryDescription mt-3 px-0 pl-md-3"
        cols="12"
        md="3"
        order="1"
      >
        <div
          v-if="user?.giftCertificatesReceived?.length > 0"
          class="gift-certificates"
        >
          <h2>
            <strong style="caret-color: #FFFFFF; color: #ffffff;"
              >Buoni sconto</strong
            >
          </h2>
          <div
            class="d-flex flex-row align-center my-2"
            v-for="gift in user.giftCertificatesReceived"
            :key="gift.userGiftCertificateId"
          >
            <strong>{{ gift.description }}</strong>
            <v-spacer />
            <v-btn
              color="primary"
              elevation="0"
              x-small
              @click="addGiftCode(gift.userGiftCertificateId)"
              >Usa</v-btn
            >
          </div>
        </div>
        <div
          v-if="categoryDescription"
          class="content"
          v-html="categoryDescription"
        />
      </v-col>
      <v-col
        cols="12"
        :md="categoryDescription ? 9 : 12"
        order="0"
        class="pa-0 pa-sm-3"
      >
        <CheckoutSummary
          :isCheckout="false"
          :orderCart="cart"
          @giftChanged="fetchUserData"
        />
        <PaymentInvoice
          @billingFormCompleted="billingFormCompleted"
          class="mt-4 mx-3 mx-sm-0 secondary--text"
          :homeDelivery="cart.homeDelivery"
        />
        <payment-lottery-form />
        <PaymentTypeList
          class="mx-3 mx-sm-0"
          :options="{
            showRememberCard: false,
            showPaymentMethodImage: false,
            showCardLogos: true,
            orderAmount: cart.totalPrice,
            showPrebilledAmountInfoText: true,
            mainBackgroundColor: 'transparent',
            confirm: { color: 'primary', class: 'secondary--text' },
            back: {
              outline: true,
              class: 'secondary--text'
            },
            titleStyle: 'secondary--text text--darken-2 text-body-1 mb-2'
          }"
          :isPaymentDisabled="!billingFormCompletedInternal"
          :paymentNameToPaymentInfo="true"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.payment {
  .gross-total {
    text-align: right;
    font-size: 28px;
    font-weight: bold;
  }
  .gross-total-btn {
    text-align: right;
  }
  .v-stepper {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      max-width: 80%;
    }
  }
  .cart-info-timeslot-card {
    .value {
      text-transform: uppercase;
    }
  }
  .categoryDescription {
    .content {
      background-color: $white;
    }
    :deep(h2) {
      font-size: 14px;
      background-color: var(--v-default-lighten1);
      text-transform: uppercase;
      padding: 10px;
      line-height: 1.5;
    }
    :deep(p) {
      padding: 10px;
      font-size: 14px;
      color: var(--v-default-base);
    }
  }

  .payment-methods {
    background-color: transparent !important;
    :deep(.v-expansion-panel) {
      background-color: $white !important;
      border: 1px solid $border-color;
      padding: 0;
      border-radius: $border-radius-root;
      margin-bottom: 20px;
      &.v-expansion-panel--active {
        border-radius: $border-radius-root;
      }
      &:last-child {
        margin-bottom: 0px;
        border-top: none;
      }
      &::before {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
      }
      .v-expansion-panel-header {
        background-color: $white;
        border-radius: $border-radius-root !important;
        margin-bottom: 5px;
        label {
          display: flex;
          padding-left: 40px;
          align-items: center;
          @media #{map-get($display-breakpoints, 'xs-only')} {
            align-items: flex-start;
          }
          .mobile.images-payment-type {
            display: flex;
            justify-content: flex-start;
          }
        }

        .payment-method-img {
          height: 50px !important;
        }
      }
      .v-expansion-panel-content__wrap {
        padding: 12px 24px !important;
        border-radius: $border-radius-root !important;

        .prebilled-amount {
          margin-bottom: 20px;
        }
        .caption {
          margin-top: 20px;
        }
      }

      .prebilledAmountInfo {
        margin-bottom: 20px;
        .text {
          color: var(--v-primary-lighten1);
        }
        .sub {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import PaymentStepper from "@/components/payment/PaymentStepper.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import PaymentInvoice from "@/components/payment/PaymentInvoice.vue";

import AnalyticsService from "~/service/analyticsService";

import productMixins from "~/mixins/product";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { mapActions, mapState } from "vuex";
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import PaymentLotteryForm from "@/components/payment/PaymentLotteryForm.vue";
import RegistrationService from "~/service/userService";

export default {
  name: "Checkout",
  mixins: [login, productMixins, cartInfo, categoryMixins],
  components: {
    CategoryTitle,
    PaymentStepper,
    PaymentTypeList,
    PaymentInvoice,
    CheckoutSummary,
    PaymentLotteryForm
  },
  data() {
    return {
      error: null,
      loading: false,
      billingFormCompletedInternal: false,
      emailKey: 1,
      showAlternativesTooltip: false,
      valid: false,
      sellCondition: false,
      user: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    categoryDescription() {
      return this.$ebsn.meta(this.category, "category_info.DESCRIPTION");
    }
  },

  methods: {
    ...mapActions({
      lockCart: "cart/lockCart",
      cartAddGiftCode: "cart/addGiftCode"
    }),
    billingFormCompleted(completed) {
      this.billingFormCompletedInternal = completed;
    },
    async goToPayment() {
      let vm = this;
      vm.loading = true;
      try {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            await vm.lockCart();
            vm.$router.push({
              name: "Payment"
            });
          }
        }
      } catch (err) {
        await this.needTimeslot();
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    },
    async addGiftCode(giftCode) {
      await this.cartAddGiftCode(giftCode);
      this.fetchUserData();
    },
    async fetchUserData() {
      this.user = await RegistrationService.getUserDetail(true);
    }
  },
  mounted() {
    this.fetchUserData();
    AnalyticsService.beginCheckout(this.cart);
  }
};
</script>
