var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"payment",class:{ 'w-90': _vm.$vuetify.breakpoint.lgAndUp }},[_c('PaymentStepper',{staticClass:"mx-0 mx-sm-auto mt-3 mt-sm-6",attrs:{"step":3}}),_c('CategoryTitle',{attrs:{"category":_vm.category,"showOnMobile":true,"centered":_vm.$vuetify.breakpoint.xs,"showDescription":false}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"categoryDescription mt-3 px-0 pl-md-3",attrs:{"cols":"12","md":"3","order":"1"}},[(_vm.user?.giftCertificatesReceived?.length > 0)?_c('div',{staticClass:"gift-certificates"},[_c('h2',[_c('strong',{staticStyle:{"caret-color":"#FFFFFF","color":"#ffffff"}},[_vm._v("Buoni sconto")])]),_vm._l((_vm.user.giftCertificatesReceived),function(gift){return _c('div',{key:gift.userGiftCertificateId,staticClass:"d-flex flex-row align-center my-2"},[_c('strong',[_vm._v(_vm._s(gift.description))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","elevation":"0","x-small":""},on:{"click":function($event){return _vm.addGiftCode(gift.userGiftCertificateId)}}},[_vm._v("Usa")])],1)})],2):_vm._e(),(_vm.categoryDescription)?_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.categoryDescription)}}):_vm._e()]),_c('v-col',{staticClass:"pa-0 pa-sm-3",attrs:{"cols":"12","md":_vm.categoryDescription ? 9 : 12,"order":"0"}},[_c('CheckoutSummary',{attrs:{"isCheckout":false,"orderCart":_vm.cart},on:{"giftChanged":_vm.fetchUserData}}),_c('PaymentInvoice',{staticClass:"mt-4 mx-3 mx-sm-0 secondary--text",attrs:{"homeDelivery":_vm.cart.homeDelivery},on:{"billingFormCompleted":_vm.billingFormCompleted}}),_c('payment-lottery-form'),_c('PaymentTypeList',{staticClass:"mx-3 mx-sm-0",attrs:{"options":{
          showRememberCard: false,
          showPaymentMethodImage: false,
          showCardLogos: true,
          orderAmount: _vm.cart.totalPrice,
          showPrebilledAmountInfoText: true,
          mainBackgroundColor: 'transparent',
          confirm: { color: 'primary', class: 'secondary--text' },
          back: {
            outline: true,
            class: 'secondary--text'
          },
          titleStyle: 'secondary--text text--darken-2 text-body-1 mb-2'
        },"isPaymentDisabled":!_vm.billingFormCompletedInternal,"paymentNameToPaymentInfo":true}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }