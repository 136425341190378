<template>
  <div>
    <div class="d-flex align-center">
      <v-checkbox
        class="mt-2 conditions text-uppercase"
        hide-details
        required
        v-model="sellCondition"
        false-value="false"
        true-value="true"
      >
      </v-checkbox>
      <i18n path="payment.salesCondition" tag="h3" class="mt-3">
        <template v-slot:link>
          <a
            href="/page/condizioni-di-vendita-aliperme"
            target="_blank"
            class="text-decoration-none"
          >
            <span class="primary--text">
              {{ $t("payment.salesConditionLink") }}</span
            >
          </a>
        </template>
      </i18n>
    </div>
    <div class="text-body-1">{{ $t("payment.salesConditionDesc") }}</div>
    <div class="my-5">
      <h3 class="mb-1">{{ $t("payment.insertSecondaryEmailTitle") }}</h3>

      <v-form
        class="d-flex mb-5"
        ref="secondaryemailform"
        v-model="isSecondaryEmailFormValid"
      >
        <v-row no-gutters>
          <v-col cols="12" class="d-flex align-start align-sm-center mb-3">
            <v-checkbox
              v-model="showSecondaryEmail"
              @change="updateSecondaryEmailPreferences"
              class="mt-0"
              hide-details
            >
            </v-checkbox>
            <p class="ma-0">{{ $t("payment.insertSecondaryEmailText") }}</p>
          </v-col>
          <v-col
            cols="12"
            class="d-flex"
            v-if="showSecondaryEmail"
            :style="
              $vuetify.breakpoint.xs ? 'max-width: 100%;' : 'max-width: 50%;'
            "
          >
            <v-text-field
              v-model="newSecondaryEmail"
              :placeholder="`${$t('profile.contacts.secondaryEmail')}`"
              outlined
              dense
              :rules="emailRules"
            />
            <v-btn
              class="ml-1"
              color="primary"
              depressed
              :disabled="!isSecondaryEmailFormValid"
              @click="updateSecondaryEmail()"
              :loading="loadingSecondaryEmailChange"
            >
              {{ $t("common.confirm") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <h3 class="mb-1">{{ $t("payment.insertPhoneTitle") }}</h3>
      <div class="text-body-1">{{ $t("payment.insertPhoneDesc") }}</div>
      <v-form ref="form" v-model="valid" class="mt-4">
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="d-flex flex-row">
            <v-text-field
              dense
              v-model="billingData.phone"
              outlined
              required
              :rules="isNumberValid"
              @blur="changed"
            />
            <v-btn
              color="primary"
              class="ml-1"
              elevation="0"
              height="40"
              large
              :disabled="!valid"
              @click="updateBillingDetails"
            >
              {{ $t("payment.phoneSave") }}
            </v-btn>
          </v-col>
          <v-col v-if="cart.shippingAddress.deliveryServiceId == 2" cols="12">
            <v-checkbox
              dense
              color="primary"
              hide-details
              class="pt-0"
              v-model="needSms"
              @click="toggleSms"
            >
              <template v-slot:label>
                <span>{{ $t("payment.smsLabel") }}</span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<style scoped lang="scss">
h3 {
  line-height: 1.5;
}
</style>

<script>
import RegistrationService from "~/service/userService";
import AliRegistrationService from "@/service/aliRegistrationService";
import userService from "~/service/userService";
import cloneDeep from "lodash/cloneDeep";
import { mapActions, mapGetters, mapState } from "vuex";
import { mapCartInfo, setPersonInfo, getPersonInfo } from "~/service/ebsn";

import {
  requiredValue,
  isEmail,
  isGenericPhone
} from "~/validator/validationRules";

export default {
  name: "PaymentInvoice",
  data() {
    return {
      valid: false,
      dirty: false,
      emailRules: [requiredValue("Digitare l'e-mail secondaria"), isEmail()],
      isNumberValid: [isGenericPhone(), requiredValue()],
      showSecondaryEmail: false,
      isSecondaryEmailFormValid: false,
      newSecondaryEmail: null,
      loadingSecondaryEmailChange: false,
      phoneConfirmed: false,
      billingData: {
        phone: null
      },
      needSms: null
    };
  },
  computed: {
    ...mapCartInfo({ sellCondition: "accept_sell_condition" }),
    ...mapGetters({
      user: "cart/getUser"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    }),

    changed() {
      if (this.phoneConfirmed) {
        this.validate;
      }
    },
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          console.log(_this.sellCondition);
          _this.valid =
            _this.$refs.form.validate() &&
            _this.phoneConfirmed &&
            _this.sellCondition === "true";
          _this.$emit("billingFormCompleted", _this.valid);
        }
      }, 200);
    },
    checkSecondaryEmail() {
      if (this.user && this.user.contact.email2) {
        this.newSecondaryEmail = this.user.contact.email2;
        this.showSecondaryEmail = true;
      } else {
        this.newSecondaryEmail = "";
        this.showSecondaryEmail = false;
      }
    },

    async updateSecondaryEmail() {
      let _this = this;
      let userData = cloneDeep(this.user);
      try {
        _this.response = {};
        _this.loadingSecondaryEmailChange = true;
        if (_this.$refs.secondaryemailform.validate()) {
          if (!userData.contact) {
            userData.contact = {};
          }
          userData.contact.email2 = _this.newSecondaryEmail;
          let res = await userService.updateContact(userData);
          _this.loadingSecondaryEmailChange = false;
          if (res) {
            _this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingSecondaryEmailChange = false;
      }
    },
    updateSecondaryEmailPreferences() {
      if (!this.showSecondaryEmail) {
        this.newSecondaryEmail = null;
        this.updateSecondaryEmail();
      }
    },
    async updateBillingDetails() {
      if (this.$refs.form.validate()) {
        await AliRegistrationService.updateUserDetail(this.billingData);
        this.phoneConfirmed = true;
        this.validate();
      }
    },
    async getUserDetail() {
      let res = await RegistrationService.getUserDetail();
      if (res.phone && res.phone.length > 0) {
        this.phoneConfirmed = true;
      } else {
        this.phoneConfirmed = false;
      }
      this.needSms = getPersonInfo(res.person, 102) === "true";
      this.billingData = res;
    },
    async toggleSms() {
      setPersonInfo(this.billingData.person, 102, this.needSms);
      await this.setCartInfo({ ["need_sms"]: this.needSms });
      this.updateBillingDetails();
    }
  },
  async mounted() {
    await this.getUserDetail();
    await this.setCartInfo({ ["need_sms"]: this.needSms });
    this.validate();
    if (typeof this.sellCondition === "undefined") {
      this.sellCondition = "false";
    }
    await this.checkSecondaryEmail();
  },
  watch: {
    sellCondition() {
      this.validate();
    }
  }
};
</script>
