<template>
  <v-layout class="lottery" v-if="showLottery">
    <v-row no-gutters>
      <v-col cols="12" sm="5">
        <img
          src="https://cdn.lotteriadegliscontrini.gov.it/cdn/portale/image/layout_set_logo?img_id=155308&t=1607647618909"
          alt="Lotteria degli scontrini"
        />
      </v-col>
      <v-col cols="12" sm="7" class="pl-0 pl-sm-4">
        <v-form class="lottery-form" v-model="valid">
          <label
            class="font-weight-bold"
            v-html="$t('payment.lottery.insert')"
          />
          <v-text-field
            class="mt-1"
            solo
            dense
            v-model="lotteryCode"
            required
            :rules="lotteryCodeRules"
          />
          <v-btn
            @click="setLotteryCode"
            block
            depressed
            v-html="$t('payment.lottery.button')"
          />
        </v-form>
        <div
          class="msg mt-2 font-weight-bold"
          v-if="showMessage && !isErrorMsg"
          v-html="$t('payment.lottery.message')"
        />

        <div
          class="msg mt-2 font-weight-bold msg-error"
          v-if="showMessage && isErrorMsg"
          v-html="$t('payment.lottery.error')"
        />
      </v-col>
      <v-col cols="12" class="legend mt-4">
        <i18n path="payment.lottery.noCode.text" tag="div">
          <template v-slot:link>
            <a
              href="https://servizi.lotteriadegliscontrini.gov.it/codicelotteria"
              target="_blank"
            >
              <span class="font-weight-bold">
                {{ $t("payment.lottery.noCode.link") }}</span
              >
            </a>
          </template>
        </i18n>
      </v-col>
      <v-col cols="12" class="legend">
        <div v-html="$t('payment.lottery.invoice')" />
      </v-col>
    </v-row>
  </v-layout>
</template>

<style lang="scss">
.lottery {
  color: #fff;
  background-color: #225688;
  padding: 20px;
  margin-top: 20px;
  img {
    max-width: 100%;
  }
  label {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    color: #fff;
    font-size: 13px;
  }
  .legend {
    font-size: 14px;
  }
  a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
  }
  .msg {
    padding: 10px 0;
    font-size: 16px;
  }
  .msg-error {
    color: #ff0000;
  }

  .v-text-field {
    width: 100%;
    .v-text-field__details {
      margin-bottom: 0 !important;
    }
  }
  .v-messages__message {
    font-weight: bold;
  }
  .v-btn {
    border-radius: 6px;
  }
  .lottery-form {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
</style>

<script>
import { requiredValue } from "~/validator/validationRules";
import { isLotteryCode } from "@/customValidationRules";
import RegistrationService from "@/service/aliRegistrationService";
import { getPersonInfo } from "~/service/ebsn";
import { mapGetters } from "vuex";
export default {
  name: "PaymentLotteryForm",
  props: {
    deliveryServiceIds: { type: String },
    personInfoTypeId: { type: Number }
  },
  data() {
    return {
      serviceIdConfirmed: false,
      warhouseIdConfirmed: false,
      showLottery: false,
      showMessage: false,
      isErrorMsg: false,
      lotteryCode: null,
      valid: false,
      lotteryCodeRules: [requiredValue(), isLotteryCode()]
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    checkDeliveryServiceIds() {
      if (this.deliveryServiceIds) {
        //se ho specificato il parametro attivo la lotteria solo per certi delivery service
        var ids = this.deliveryServiceIds.split(",");
        for (var i = 0; i < ids.length; i++) {
          if (ids[i] == this.cart.shippingAddress.deliveryServiceId) {
            this.serviceIdConfirmed = true;
            break;
          }
        }
      } else {
        //altrimenti sempre visibile
        this.serviceIdConfirmed = true;
      }
    },

    checkWarehouseIds() {
      if (this.warehouseIds) {
        var ids = this.warehouseIds.split(",");
        for (var i = 0; i < ids.length; i++) {
          if (ids[i] == this.cart.warehouse.warehouseId) {
            this.warhouseIdConfirmed = true;
            break;
          }
        }
      } else {
        this.warhouseIdConfirmed = true;
      }
    },
    setLotteryCode() {
      if (this.valid) {
        let _this = this;
        RegistrationService.setLotteryCode(this.lotteryCode).then(
          function(response) {
            console.log(response);
            _this.isErrorMsg = false;
            _this.showMessage = true;
          },
          function(error) {
            console.log(error);
            _this.isErrorMsg = true;
            _this.showMessage = true;
          }
        );
      }
    },
    loadLotteryCode() {
      var id = this.personInfoTypeId ? this.personInfoTypeId : 100;
      this.lotteryCode = getPersonInfo(this.user.person, id);
    }
  },
  created() {
    this.loadLotteryCode();
    this.checkDeliveryServiceIds();
    this.checkWarehouseIds();
    if (this.serviceIdConfirmed && this.warhouseIdConfirmed) {
      this.showLottery = true;
    }
  }
};
</script>
